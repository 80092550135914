import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import useDebounce from '../../components/TopBar/use-debounce';
import { Form, Image, Spinner, Dropdown } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import BottomBar from '../../components/BottomBar';
import Navigation from '../../components/TopBar';
import MobileTopBar from '../../components/MobileTopBar';

import { useStore } from '../../store';
import { HomeDiv, SearchBar, FooterDiv } from './elements/styles';
import search from '../../assets/icons/search.svg';
import logo from '../../assets/images/Logo-Black.svg';
import background from '../../assets/images/Background.svg';
import smWave from '../../assets/images/homeg10.svg';
import Wave from '../../assets/images/homeg10sm.svg';
import Carousel from '../../components/Carousel';
// import suggest from "../../assets/icons/suggest.svg";
import suggest from '../../assets/icons/recent.svg';
import linkedin from '../../assets/icons/Icon-Linkedin.svg';

const Home = observer(() => {
  const location = useHistory();
  const [searchDropdown, setSearchDropdown] = useState(false);

  const [history, setHistory] = useState(false);

  const [searchValue, setSearchValue] = useState();
  const { searchStore, accountStore } = useStore();
  // const [searchTerm, setSearchTerm] = useState('');

  const {
    setKey,
    listSuggestResults,
    // suggestedResults,
    setSuggestResults,
    listPreviousSearches,
    previousSearches,
    interests,
    listInterests,
    industries,
    listSearchinRoles,
    searchinRoles,
    listIndustries,
    clearTypeFilters,
  } = searchStore;

  const {
    carousel,
    onFetchCarousel,
    isCarouselLoading,
    listFooter,
    footer,
    listRoles,
    user,
  } = accountStore;
  useEffect(() => {
    if (user?.consent === false) {
      location?.push(`/terms`);
    }
  }, [location, user]);
  useEffect(() => {
    onFetchCarousel();
  }, [onFetchCarousel]);

  useEffect(() => {
    listInterests();
  }, [listInterests]);

  useEffect(() => {
    listRoles();
  }, [listRoles]);

  useEffect(() => {
    listFooter();
  }, [listFooter]);

  useEffect(() => {
    listPreviousSearches();
  }, [listPreviousSearches]);

  useEffect(() => {
    listSearchinRoles();
  }, [listSearchinRoles]);

  useEffect(() => {
    listIndustries();
  }, [listIndustries]);

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    setKey(data.key);
    location.push(`/search?q=${data.key}`);
  };

  const debouncedSearchTerm = useDebounce(searchValue, 500);

  const suggestResults = (key) => {
    setSuggestResults([]);
    setSearchValue(key);
    setSearchDropdown(true);

    if (key.length < 1) {
      setHistory(true);
    } else {
      setHistory(false);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm) listSuggestResults(debouncedSearchTerm);
  }, [debouncedSearchTerm, listSuggestResults]);

  const suggestSearch = (key, searchtype) => {
    setKey(key);
    if (searchtype === '') {
      location.push(`/search?q=${key}`);
    } else {
      location.push(`/search?q=${encodeURIComponent(key)}&in=${searchtype}`);
    }
  };

  const refSearch = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        refSearch &&
        refSearch.current &&
        !refSearch.current.contains(event.target)
      ) {
        setSearchDropdown(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
  }, [refSearch, setSearchDropdown]);

  return (
    <>
      <Navigation />

      <HomeDiv>
        <Helmet>
          <title>Members Home | 121connects</title>
          <meta
            id='meta-description'
            name='description'
            content="121connects helps you find and network with a curated list of India's leading CIOs, CISOs, CSOs, and IT Leaders in this members exclusive community "
          />
          <style>
            {`body { 
            background: 0; 
            background-image:  url(${background}); 
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            min-height: 100vh; }`}
          </style>
        </Helmet>
        <MobileTopBar />
        <div className='glass'>
          {/* <Image className="logo d-block d-lg-none" src={logo} /> */}
          <div className='holder'>
            <h1 className='connect'>{process.env.REACT_APP_HOME_TEXT} <br/> {process.env.REACT_APP_HOME_TEXT_SUB}</h1>
            <div className='search-initiator d-flex'>
              Discover By{' '}
              <Dropdown>
                <Dropdown.Toggle
                  variant='basic'
                  id='more'
                  className='a_selectSidebar d-flex align-items-center'
                >
                  Interest
                  <i className='droparrow' />
                </Dropdown.Toggle>

                <Dropdown.Menu align='bottom'>
                  <i className='arrow d-none d-lg-block' />

                  <div className='d-lg-flex'>
                    <div>
                      {interests
                        ?.slice(0, Math.ceil(interests?.length / 2))
                        .map((item) => (
                          <Dropdown.Item
                            as='button'
                            key={item.id}
                            onClick={() => {
                              // setSearchIn('interest');
                              suggestSearch(item.name, 'interest');
                            }}
                          >
                            {item.name}
                          </Dropdown.Item>
                        ))}
                    </div>
                    <div>
                      {interests
                        ?.slice(Math.ceil(interests?.length / 2))
                        .map((item) => (
                          <Dropdown.Item
                            as='button'
                            key={item.id}
                            onClick={() => {
                              // setSearchIn('interest');
                              suggestSearch(item.name, 'interest');
                            }}
                          >
                            {item.name}
                          </Dropdown.Item>
                        ))}
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle
                  variant='basic'
                  id='more'
                  className='a_selectSidebar d-flex align-items-center'
                >
                  Responsibility
                  <i className='droparrow' />
                </Dropdown.Toggle>

                <Dropdown.Menu align='bottom'>
                  <i className='arrow' />

                  <div className='d-lg-flex'>
                    <div>
                      {searchinRoles
                        ?.slice(0, Math.ceil(searchinRoles?.length / 2))
                        .map((item) => (
                          <Dropdown.Item
                            as='button'
                            key={item.id}
                            onClick={() => {
                              // setSearchIn('role');
                              suggestSearch(item.rolel2, 'role');
                            }}
                          >
                            {item.rolel2}
                          </Dropdown.Item>
                        ))}
                    </div>
                    <div>
                      {searchinRoles
                        ?.slice(Math.ceil(searchinRoles?.length / 2))
                        .map((item) => (
                          <Dropdown.Item
                            as='button'
                            key={item.id}
                            onClick={() => {
                              // setSearchIn('role');
                              suggestSearch(item.rolel2, 'role');
                            }}
                          >
                            {item.rolel2}
                          </Dropdown.Item>
                        ))}
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle
                  variant='basic'
                  id='more'
                  className='a_selectSidebar d-flex align-items-center'
                >
                  Industry
                  <i className='droparrow' />
                </Dropdown.Toggle>

                <Dropdown.Menu align='bottom'>
                  <i className='arrow d-none d-lg-block' />

                  <div className='d-lg-flex'>
                    <div>
                      {industries
                        ?.filter((item) => item.industryl1 !== 'OTHERS')
                        ?.slice(0, Math.ceil(industries?.length / 2))
                        .map((item) => (
                          <Dropdown.Item
                            as='button'
                            key={item.id}
                            onClick={() => {
                              // setSearchIn('industry');
                              suggestSearch(item.industryl1, 'industry');
                            }}
                          >
                            {item.industryl1}
                          </Dropdown.Item>
                        ))}
                    </div>
                    <div>
                      {industries
                        ?.filter((item) => item.industryl1 !== 'OTHERS')
                        ?.slice(Math.ceil(industries?.length / 2))
                        .map((item) => (
                          <Dropdown.Item
                            as='button'
                            key={item.id}
                            onClick={() => {
                              // setSearchIn('industry');
                              suggestSearch(item.industryl1, 'industry');
                            }}
                          >
                            {item.industryl1}
                          </Dropdown.Item>
                        ))}
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <SearchBar
              onSubmit={handleSubmit(onSubmit)}
              className='home-search'
              onChange={(e) => {
                // setSearchTerm(e.target.value);
                clearTypeFilters();
                // suggestResults(e.target.value);
              }}
              onBlur={() =>
                setTimeout(() => {
                  setSuggestResults([]);
                }, 500)
              }
              onFocus={(e) => suggestResults(e.target.value)}
              ref={refSearch}
            >
              <Form.Control
                type='search'
                id='search-tablet'
                data-testid='search-input-tablet'
                placeholder='Enter Person or Company name'
                aria-label='Search'
                autoComplete='off'
                aria-describedby='basic-addon1'
                {...register('key', { required: true })}
              />

              <button className='search-button' type='submit'>
                <Image src={search} className='search-icon' />
              </button>
              <Image className='wave d-none d-lg-block' src={smWave} />

              {searchDropdown && history && (
                <div className='suggested'>
                  <div className='search-head'>Recent searches</div>
                  {previousSearches?.map((item) => (
                    <div
                      key={item.keyword}
                      className='search-chip d-flex'
                      onClick={() => suggestSearch(item.keyword, '')}
                    >
                      <Image src={suggest} />
                      <div className='key'>{item.keyword}</div>
                    </div>
                  ))}
                </div>
              )}
            </SearchBar>

            

            {/* <SuggestChips className="d-flex chips">
              <Image src={suggest} />
              {previousSearches?.map((item) => (
                <div
                  key={item.keyword}
                  className="search-chip"
                  onClick={() => suggestSearch(item.keyword)}
                >
                  {item.keyword}
                </div>
              ))}
            </SuggestChips> */}

            <Image className='wave d-lg-none' src={Wave} />
            {isCarouselLoading ? (
              <div
                data-testid='app-loader'
                className='d-flex justify-content-center align-items-center w-75 mt-5'
              >
                <Spinner animation='grow' size='lg' />
              </div>
            ) : (
              <Carousel carousel={carousel} />
            )}
          </div>
          {/* <div className="img-div"></div> */}
        </div>
      </HomeDiv>
      <FooterDiv className='d-none d-lg-flex'>
        <div className='footer d-flex align-items-center justify-content-between'>
          <Image src={logo} className='footer-logo' />
          <div className='d-flex foot-contents'>
            {footer.map((item) => (
              <div className='button-group'>
                <a href={item.link} target='_blank' rel='noreferrer'>
                  <button className='link' id='terms'>
                    {item.text}
                  </button>
                </a>
              </div>
            ))}

            <p className='copyright'>
              {' '}
              {process.env.REACT_APP_FOOTER_COPYRIGHT}
            </p>
            <div className='icons'>
              <a
                href={process.env.REACT_APP_API_LINKEDIN_LINK}
                target='_blank'
                rel='noreferrer'
              >
                <img src={linkedin} className='linked-logo' alt='icon' />{' '}
              </a>
            </div>
          </div>
        </div>
      </FooterDiv>
      <BottomBar />
    </>
  );
});

export default Home;
