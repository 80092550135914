import styled from 'styled-components';
import { Form } from 'react-bootstrap';

export const HomeDiv = styled.div`
  padding: 11px 10px;
  min-height: 300px;
  position: relative;

  .img-div {
    width: 350px;
    height: 350px;
    // width: 30vh;
    // height: 30vh;
    background: #c4c4c4;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;

    @media only screen and (min-width: 992px) {
      position: absolute;
      bottom: 80px;
      right: 20px;
    }

    @media only screen and (max-width: 375px) {
      width: 270px;
      height: 270px;
    }
  }

  .glass {
    margin: 0px;
    border-radius: 5px;
    background: rgba(235, 255, 254, 0.2);
    padding: 0px 0px 70px 0px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    min-height: 76vh;
    position: relative;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    position: relative;

    @media only screen and (max-width: 600px) {
      margin-top: 87px;
    }

    .wave {
      position: absolute;
      z-index: -1;
      top: -130px;
      left: 50px;
      width: 315px;
      height: 250px;
      @media only screen and (max-width: 360px) {
        width: 75vw;
      }
    }

    @media only screen and (min-width: 992px) {
      padding: 0 0 0 100px;
      min-height: 72vh;

      .wave {
        top: -105px;
        left: 480px;
        @media only screen and (max-width: 1308px) {
          width: 750px;
          top: -90px;
          left: 520px;
        }
      }
      @media only screen and (max-height: 837px) {
        min-height: 70vh;
      }
      @media only screen and (max-height: 800px) {
        min-height: 68vh;
      }
      @media only screen and (max-height: 740px) {
        min-height: fit-content;
      }
    }

    .mob-topbar {
      padding: 40px 19px 0px 19px;
    }

    .holder {
      margin-top: auto;
      margin-bottom: auto;
      padding: 0px 22px 0px 19px;
      position: relative;

      @media only screen and (max-width: 600px) {
        width: 359px;
        margin: auto;
        @supports (-webkit-touch-callout: none) {
          width:100%;
        }
      }
    }

    h1 {
      font-size: 28px;
      font-weight: 800;
      color: #fff;

      padding: 0px 22px 5px 0px;

      @media only screen and (min-width: 992px) {
        color: #ffffff;
        font-family: Lato;
        font-style: normal;
        font-weight: 800;
        font-size: 48px;
        line-height: 110%;
      }
      @media only screen and (min-width: 1367px){
        width: auto;
      }
      @media only screen and (max-width: 600px) {
        max-width: 587px;
        font-size: 33px;
      }
      @media only screen and (max-height: 700px) {
        font-size: 28px;
        margin-top: 29px;
      }
    }
  }
  .home-search {
    position: relative;
   

    .wave {
      position: absolute;
      z-index: -1;
      top: -270px;
      left: 600px;
      width: 566px;
      height: 400px;

      @media only screen and (max-width: 1300px) {
        width: 400px;
        top: -240px;
      }
      @media only screen and (max-width: 1130px) {
        width: 300px;
        top: -215px;
      }
    }
    @media only screen and (min-width: 992px) {
      width: fit-content;

      .suggested {
        // top: 72px;
      }
      .form-control {
        width: 350px;
        height: 40px;
        font-size: 18px;
        padding-left: 36px;
       
        

        @media screen and (min-width: 992px) {
          .suggested {
            top: 74px;
          }
        }
      }
      .search-button {
        margin-top: 10px;
        margin-left: -55px;
       
       
      }
      .search-icon {
        width: 25px;
        height: 18px;
      
      }
    }
  }
  
  .seeAll {
    color: #00aeae;
    cursor: pointer;
    font-size: 12px;
  }

  .search-initiator {
    font-size: 25px;
    color: #cdeaf0;
    z-index: 20;
   

    @media only screen and (max-width: 600px) {
      margin-top: 2px;
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 120%;
      /* identical to box height, or 16px */
    }

    .dropdown {
      margin-left: 30px;

      @media only screen and (max-width: 500px) {
        margin-left: 10px;
      }     
    }

    .droparrow {
      margin: 0 0 2px 5px;
      border-left: 1px solid #fff;
      border-top: 1px solid #fff;
      padding: 4px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }

    // .arrow {
    //   position: absolute;
    //   top: -5px;
    //   left: 180px;
    //   border: solid #fff;
    //   background: #fff;
    //   border-width: 0 3px 3px 0;
    //   display: inline-block;
    //   padding: 3px;
    //   transform: rotate(-135deg);
    //   -webkit-transform: rotate(-135deg);
    // }

    .dropdown-toggle {
      color: #fff;
      font-weight: 600;
      font-size: 16px;
      box-shadow: none;
      padding: 0;
      @media only screen and (min-width:992px){
         letter-spacing: 1px;
      }
     

      :after {
        display: none;
      }
    }

    .a_selectSidebar {
      font-size: 25px;
      @media only screen and (max-width: 600px) {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 120%;
        /* identical to box height, or 16px */

        color: #ffffff;
      }
    }

    .dropdown-item {
      color: #00aeae;
      font-size: 10px;
    }
    .dropdown-menu {
      padding: 10px;
      width: fit-content;
      background: #fff;

      .nav-link {
        color: #111;
      }

      @media screen and (min-width: 992px) {
        left: -150px !important;
        padding: 40px 16px 24px 43px;

        .dropdown-item {
          font-size: 12px;
        }
      }
    }
  }
  
`;

export const SearchBar = styled(Form)`
  position: relative;
  @media screen and (min-width: 992px) {
    margin-top: 7.5px;
    height: 10px;
  }

  display: flex;
  .form-control {
    background: #fafafa;
    height: 54px;
    width: 337px;
    color: #111 !important;
    font-size: 14px;
    margin-top: 10px;
    @media screen and (min-width: 992px) {
      width: 250px;
      height: 30px;
    }
  }
  .form-control::placeholder {
    color: #d0d0d0;
    font-weight: 500;
  }
  .form-control:focus {
    box-shadow: none;
  }

  .search-button {
    border: none;
    background: transparent;
    color: #7a7a7a;
    border-right: none;
    font-size: 20px !important;
    margin: 10px 0px 10px -35px;
    @media only screen and (min-width:992px){
      margin: 19px 0px 23.6px -35px;
    }
    @supports (-webkit-touch-callout: none) {
      margin-left : -56px;
    }
  }
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  .suggested {
    position: absolute;
    background: #fff;
    border: 0.5px solid #cdeaf0;
    padding: 20px 0px 30px 30px;
    width: 75%;
    max-width: 415px;
    z-index: 10000;
    border-radius: 5px;
    color: #777777;
    top: 53px;

    .suggest-card {
      margin-bottom: 18px;
      cursor: pointer;
    }

    .suggest-name {
      font-size: 13px;
    }

    .suggest-info {
      font-size: 10px;
    }

    .search-head {
      font-size: 12px;
      font-weight: 600;
    }

    .search-chip {
      padding-top: 12px;
      font-size: 10px;
      font-weight: 600;
      color: #666666;
      cursor: pointer;

      .key {
        margin-left: 4px;
      }
    }

    @media screen and (min-width: 992px) {
      width: 250px;
    }
  }
`;

export const SuggestChips = styled.div`
  padding-top: 13px;
  overflow: hidden;

  .search-chip {
    margin-left: 10px;
    padding: 3px 9px 3px 6px;
    color: #fff;
    font-size: 10px;
    background: #3e8abb;
    border: 0.5px solid #3b8ab9;
    border-radius: 3px;
    cursor: pointer;
  }
`;

export const FooterDiv = styled.div`
  padding: 9px 11px 20px 11px;
  font-size: 14px;
  color: #c9e5f1;

  .footer-logo {
    width: 152px;
    height: 63px;
  }

  .footer {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    flex-wrap: wrap;
  }

  .button-group {
    padding-right: 11px;
  }

  #privacy {
    border-right: 0.5px solid #c9e5f150;
  }

  .link {
    background: transparent;
    border: 0;
    color: #c9e5f1;
    padding-right: 10px;
    padding-left: 10px;
  }
  .icons{
    cursor:pointer;
  }
  .face-logo {
    margin-right: 19px;
  }
  .linked-logo {
    margin-right: 21px;
  }
  .twitter-logo {
    margin-right: 8px;
  }
  #contact {
    border-right: 0.5px solid #c9e5f150;
  }
  #terms {
    border-right: 0.5px solid #c9e5f150;
  }

 
  .copyright{
    margin-right: 18px;
  }
`;
